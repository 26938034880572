
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class AuthSuccess extends Vue {

  created() {
    if (!this.$route.query.success) {
      this.$router.push('/')
    }
  }
}
